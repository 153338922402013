import React from 'react'
import useBgLoaded from '../custom-hooks/useBgLoaded'

const Subscribe = () => {
  const { ref, inView, bgLoaded, setBgLoaded } = useBgLoaded();

	if (inView && !bgLoaded) {
		setBgLoaded(true);
	}
  return (
    <div ref={ref} className={`${
						bgLoaded ? 'bg-subscribe-image' : ''
					} h-[400px] md:h-[752px] bg-no-repeat bg-full md:bg-cover relative font-dmsans`}>
    {/* Sign up Today */}
<div className='absolute top-[24px] left-[24px] md:top-[69px] md:left-[48px] flex flex-col items-start gap-1 md:gap-[12px] w-[223px] h-[165px] md:w-[448px] md:h-[293px] rounded-[20px] border-[2px] bg-subcribeBackground border-[#F5F5F54D] backdrop-blur-[10px] py-4 px-4 md:px-[32px] md:py-[53px]'>
<h4 className='font-[700] text-[14px] leading-[18px] md:text-[24px] md:leading-[31px] text-white'>Run Your Entire Auto Repair Shop with One Tool!</h4>
<p className='text-[12px] leading-[16px] md:text-[16px] font-[400] md:leading-[21px] text-[#E2E0E0]'>Boost your business efficiency with Hamzaa</p>
<a href="https://dashboard.hamzaa.ca/signup" className='mt-1 md:mt-[20px] w-[130px] h-[52px] md:w-[157px] md:h-[60px] rounded-[8px] border-[2px] border-[#F0FDFA] py-4 px-3 md:py-[18px] md:px-[20px] bg-primary text-[14px] leading-[20px] md:text-[16px] font-[700] md:leading-[24px] flex justify-center items-center text-center text-[#F0FDFA]'>Sign Up Today</a>
</div>
{/* Subscribe to Our News Letter */}
<div className="absolute bottom-0 right-0 left-0 w-full bg-subcribeBackground py-4 md:py-[40px] md:px-[30px] flex flex-col items-center gap-[9px] md:gap-[19px] h-[89px] md:h-[185px]">
  <h4 className='font-[700] text-[12px] leading-[16px] md:text-[24px] md:leading-[31px] text-[#F0FDFA]'>Subscribe to our Newsletter</h4>
  <form className='flex px-[30px] md:px-0'>
    <input type='email' placeholder='Enter your email address' className='w-full md:w-[511px] md:h-[56px] h-[32px] rounded-[4px] rounded-tr-none rounded-br-none py-[17px] px-[16px] bg-[#F9FBFE] text-[10px] leading-[13px] md:text-[16px] font-[400] md:leading-[21px] text-[#161414] outline-primary'/>
    <button className=' w-[67px] md:w-[154px] h-[32px] md:h-[56px] rounded-tr-[4px] rounded-br-[4px] flex justify-center items-center py-[17px] px-[30px] bg-primary font-montserrat font-[500] text-[10px] leading-[12px] md:text-[16px] md:leading-[20px] text-center text-[#F9FBFE]'>Subscribe</button>
  </form>
</div>
    </div>
  )
}

export default Subscribe