import React, {useState} from "react";
import PricingBox from "../components/PricingBox";
import { Link } from "react-router-dom";

const Pricing = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };
  const pricings = [
    {
      price: 0,
      month: "3",
      plan: "Free",
      description: "Perfect plan for starters",
      button: "Try for Free",
      perks: ["2 Customer details", "Perk 1", "Perk 1", "Perk 1"],
    },
    {
      price: 150,
      month: "",
      plan: "Basic",
      description: "For smaller shops that wants to streamline workflows",
      button: "Choose Plan",
      popular: "MOST POPULAR",
      perks: [
        "Customer details",
        "Inventory management",
        "Invoicing",
        "VIN lookup and plate lookup (Carfax, NHTSA)",
      ],
    },
    {
      price: 200,
      month: "",
      plan: "Unlimited",
      description: "For larger shops needing advanced to do more",
      button: "Choose Plan",
      perks: [
        "Customer details",
        "Inventory management & Invoicing",
        "Analytics and reports",
        "Parts Ordering (Nexpart, Partstech)",
        "VIN lookup and plate lookup (Carfax, NHTSA)",
      ],
    },
  ];
  return (
    <div className="mt-[160px] lg:mt-[176px] font-dmsans max-w-full overflow-hidden">
      <div className="flex flex-col items-center gap-[6px] px-6">
        <h1 className="font-spaceGrotesk font-[700] md:font-[500] text-[24px] leading-[40px] md:text-[32px] md:leading-[50px] tracking-[0.3px] text-black text-center">
          Choose a Pricing Plan
        </h1>
        <p className="font-[400] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.3px] text-[#48494D] text-center">
          Explore our pricing options and{" "}
          <span className="text-primary">save time</span> using Hamzaa.
        </p>
        <div className="mb-[40px] mt-[24px] lg:my-[60px] cursor-pointer" onClick={handleToggle}>
      <div className="flex w-[230px] h-[48px] lg:w-[286px] lg:h-[59px] items-center bg-primary relative rounded-[40px] py-6 px-3 lg:px-4 text-white">
        <div
          className={`w-[117px] h-[36px] lg:w-[147px] lg:h-[48px] bg-white absolute flex items-center justify-center rounded-[40px] mr-[5px] transition-all duration-300 ${
            isMonthly ? "left-0 ml-1" : "right-0"
          }`}
        >
          <h3
            className={`font-[700] text-[12px] leading-[16px] md:text-[16px] md:leading-[26px] tracking-[0.3px] ${
              isMonthly ? "text-primary" : "text-[#161414]"
            }`}
          >
            {isMonthly ? "Monthly" : "Annual"}
            {!isMonthly && (
              <span className="text-primary font-[500] text-[12px] leading-[14px] tracking-[0.3px] ml-1">
                Save 10%
              </span>
            )}
          </h3>
        </div>
        <span
          className={`${
            isMonthly ? "text-white" : "text-white"
          } font-[700] text-[12px] leading-[16px] md:text-[16px] md:leading-[26px] tracking-[0.3px]`}
        >
          Monthly
        </span>
        <span
          className={`ml-auto ${
            !isMonthly ? "text-white" : "text-white"
          } font-[700] text-[12px] leading-[16px] md:text-[16px] md:leading-[26px] tracking-[0.3px]`}
        >
          Annual
          <span className="text-black font-[500] text-[10px] leading-[10px] tracking-[0.3px] ml-1">
                Save 10%
              </span>
        </span>
      </div>
    </div>
      </div>
      <div className="flex flex-col  lg:flex-row justify-center mb-[60px] lg:mb-[76px] px-6 lg:px-0">
        {pricings.map((pricing, index) => (
          <PricingBox
            key={index}
            price={pricing.price}
            month={pricing.month}
            plan={pricing.plan}
            description={pricing.description}
            button={pricing.button}
            perks={pricing.perks}
            isPopular={pricing.popular || null}
            customClass={index === 1 ? "bg-[#F6F9FF] border border-[#D9D9D9] shadow-md" : "bg-[#FFFFFF]"}
            customButton={index === 1 ? "bg-primary border-foot border-[2px] text-white" : "text-primary bg-[#E3F5FF]"}
          />
        ))}
      </div>
      <div className="flex items-center justify-center px-6 lg:px-0 mb-[60px] lg:mb-[80px]">
        <p className="font-[400] md:font-[700] text-[16px] leading-[24px] md:text-[20px] md:leading-[30px] tracking-[0.3px] text-center text-[#161414]">
          Have more enquiry about the plans?{" "}
          <Link to='/contactus'><span className="text-primary font-[700]">Contact Us</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Pricing;
