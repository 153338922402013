import ImageOne from '../assets/images/feature-one.png';
import ImageTwo from '../assets/images/feature-two.png';
import ImageThree from '../assets/images/feature-three.png';
import ImageFour from '../assets/images/feature-four.png';
import ImageFive from '../assets/images/feature-five.png';
import ImageSix from '../assets/images/feature-six.png';

const features = [
    {id: 1,
        image: ImageOne,
        title: 'Customer Management',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
    {id: 2,
        image: ImageTwo,
        title: 'Inventory Management',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
    {id: 3,
        image: ImageThree,
        title: 'Part Ordering',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
    {id: 4,
        image: ImageFour,
        title: 'Schedule Appointment',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
    {id: 5,
        image: ImageFive,
        title: 'Invoice Generating',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
    {id: 6,
        image: ImageSix,
        title: 'VIN Lookup',
        description: "Hamzaa's innovative approach ensures that your team is always where they need to be, when they need to be, minimizing downtime and maximizing output.",
    },
]
export default features