import React from "react";
import plus from "../assets/images/plus.svg";
import close from "../assets/images/close.svg";

const FaqBox = ({ question, answer, isOpen, toggleAnswer }) => {
  return (
    <div className="flex flex-col w-full rounded-[10px] px-4 py-4 md:px-[40px] md:py-[30px] bg-white font-dmsans">
      <div className=" flex justify-between items-center md:items-start w-full" data-aos="fade-down">
        <div className="flex justify-center items-center lg:max-w-[80%] py-2 md:py-0">
          <h2 className=" font-[600] md:text-[24px]  md:leading-[36px] text-[14px] leading-[18px] tracking-[0.26px] text-[#161414]">
            {question}
          </h2>
        </div>
        <button className="flex justify-center items-center w-[16px] h-[16px] md:w-[20px] md:h-[20px]" onClick={toggleAnswer}>
          {isOpen ? (
            <img src={close} alt="Close icon" className="w-full h-full " />
          ) : (
            <img src={plus} alt="Open icon" className="w-full h-full" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="pt-[11px] animate-scaleUpVerTop ">
          <p className="text-[#48494D] font-[400] text-[12px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.26px]">
            {answer}
          </p>
        </div>
      )}
    </div>
  );
};

export default FaqBox;
