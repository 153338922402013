import React, {useState} from 'react'
import { FaAsterisk, FaCaretDown, FaEye, FaEyeSlash } from 'react-icons/fa';
import Dropdown from './DropDown'

const InputField = ({
	label,
	value,
	handleValue,
	placeholder,
	name,
	type,
	options,
	className = '',
	inputClassName = '',
	selectClassName = '',
	textareaClassName = '',
	prefix = null,
	disabled = false,
	onClick,
    asteriks = null,
	postfix,
	labelClassName
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const togglePasswordVisibility = () =>
		setIsPasswordVisible(!isPasswordVisible);
	const handleChange = (e) =>
		handleValue(
			type === 'number'
				? Math.max(0, parseInt(e.target.value) || 0)
				: e.target.value,
		);
	const handleOptionClick = (option) => {
		handleValue(option.value);
		setIsDropdownOpen(false);
	};
	const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className={`${className} font-dmsans` }>
			<label
				htmlFor={name}
				className={`text-[16px] leading-[21px] md:text-[20px] md:leading-[30px] font-[400] tracking-[0.3px] text-gray-500 mb-[6px] flex gap-1 ${label ? labelClassName : ''}`}>
				{label}
                {asteriks && (<FaAsterisk className='text-red-400 w-[6px] h-[6px] md:w-[8px] md:h-[8px] '/>)}
			</label>

			{type === 'select' ? (
				<div className='relative w-full'>
					<button
						type='button'
						onClick={toggleDropdown}
						className={`w-full h-[48px] md:h-[69px] bg-white flex justify-between items-center rounded-[10px] bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] text-black placeholder-gray-400 placeholder:text-[14px] border border-[#8E8E93] focus:border-primary focus:border-2 ${selectClassName} ${
							disabled && 'cursor-not-allowed opacity-50'
						}`}
						disabled={disabled}>
						<p>
							{options.find((opt) => opt.value === value)
								?.label || placeholder}
						</p>
						<FaCaretDown />
					</button>
					{isDropdownOpen && (
						<Dropdown
							actions={options.map((opt) => ({
								label: opt.label,
								onClick: () => handleOptionClick(opt),
							}))}
							onClose={() => setIsDropdownOpen(false)}
							position='top-[75px]'
							width='w-full'
						/>
					)}
				</div>
			) : type === 'textarea' ? (
				<textarea
					id={name}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={(e) => handleValue(e.target.value)}
					className={`w-full min-h-[120px] rounded-[13px] bg-secondary py-[14px] px-[12px] text-[15px] font-[400] text-black outline-primary placeholder-gray-400 placeholder:text-[17px] border border-[#8E8E93] focus:border-primary ${textareaClassName}`}
					disabled={disabled}
				/>
			) : (
				<div className='relative w-full flex items-center'>
					{prefix && (
						<span className='absolute left-3'>{prefix}</span>
					)}
					<input
						id={name}
						name={name}
						placeholder={placeholder}
						value={value}
						onChange={!onClick ? handleChange : null}
						type={
							type === 'password'
								? isPasswordVisible
									? 'text'
									: 'password'
								: type
						}
						className={`w-full h-[48px] md:h-[69px] rounded-[10px] bg-white py-[14px] ${
							prefix ? 'pl-14' : 'px-[12px]'
						} text-[17px] font-[400] text-black placeholder-gray-400 placeholder:text-[14px] md:placeholder:text-[17px] border border-[#8E8E93] outline-primary focus:border-primary ${inputClassName}`}
						disabled={disabled}
						onClick={onClick}
					/>
					{postfix && (
						<span className='absolute right-3'>{postfix}</span>
					)}
					{type === 'password' && (
						<button
							type='button'
							onClick={togglePasswordVisibility}
							className='absolute right-3'>
							{isPasswordVisible ? (
								<FaEyeSlash size={20} />
							) : (
								<FaEye size={20} />
							)}
						</button>
					)}
				</div>
			)}
		</div>
  )
}

export default InputField