import React, { useState } from "react";
import FaqBox from "./FaqBox";
import faqs from "../utils/faqData";

const Faq = () => {
  const [openFaq, setOpenFaq] = useState(null); // Track the open FAQ by index or ID

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index); // Open selected, close if clicked again
  };
  return (
    <div className="w-full h-auto bg-[#F6F9FF] font-dmsans">
      <div className="flex flex-col gap-[6px] items-center pt-[80px] pb-[60px]">
        <h2 className="font-spaceGrotesk font-[500] text-[24px] leading-[40px] md:text-[32px] md:leading-[40px] tracking-[0.26px] text-[#161414] ">
          FAQs
        </h2>
        <p className="font-[400] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.26px] text-[#161414] ">
          Frequently Asked Questions
        </p>
      </div>
      <div className="flex flex-col w-full gap-[16px] px-6 md:px-[73px] md:pb-[80px] pb-[60px]">
        {faqs.map((faq, index) => (
          <FaqBox key={index} question={faq.question} answer={faq.answer} 
          isOpen={openFaq === index} // Pass whether this FAQ is open
          toggleAnswer={() => toggleFaq(index)} // Toggle open/close 
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
