import React from "react";
import { Link } from "react-router-dom";
import useScrollTop from "../custom-hooks/useScrollToTop";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { LuDot } from "react-icons/lu";

const Footer = ({ footerRef }) => {
  const { scrollToTop } = useScrollTop();
  const navLinks = [
    {
      name: "Features",
      path: "/features",
    },
    { name: "About Us", path: "/aboutus" },
    { name: "Pricing", path: "/pricing" },
    { name: "Contact Us", path: "/contactus" },
  ];
  const otherLinks = [
    { name: "Career", path: '/careers' },
    { name: "Blog", path: '/' },
    { name: "Request an Analyst", path: '/request-analyst' },
  ];
  const socials = [
    {image: <FaFacebook className="w-[23px] h-[23px] text-white"/>, link: 'https://www.facebook.com/HamzaaINC'},
    {image: <FaInstagram className="w-[23px] h-[23px] text-white" />, link: ''},
    {image: <FaTwitter className="w-[23px] h-[23px] text-white" />, link: ''},
    {image: <FaLinkedin className="w-[23px] h-[23px] text-white" />, link: 'https://www.linkedin.com/company/hamzaa/'}
  ]
  return (
    <div
      ref={footerRef}
      className="border-t border-footerBorder lg:h-[367px] w-full py-[40px] md:py-[64px] md:px-[80px] px-[20px] bg-foot flex flex-col items-center font-dmsans"
    >
      <div className="flex flex-col lg:flex-row justify-between items-center w-full">
        <div className="flex flex-col items-center lg:items-start gap-[24px] w-full">
          <Link to="/" onClick={scrollToTop}>
            <h2 className="font-miltonian font-[400] text-[24px] leading-[29px] text-white">
              Hamzaa
            </h2>
          </Link>
          <div className="flex items-center gap-[32px]">
            {navLinks.map((nav, index) => (
              <Link
                onClick={scrollToTop}
                to={nav.path}
                key={index}
                className="font-[400] text-[12px] leading-[16px] text-white"
              >
                {nav.name}
              </Link>
            ))}
          </div>
          <div className="flex items-center gap-[32px]">
            {otherLinks.map((nav, index) => (
              <Link
                onClick={scrollToTop}
                to={nav.path}
                key={index}
                className="font-[400] text-[12px] leading-[16px] text-white"
              >
                {nav.name}
              </Link>
            ))}
          </div>
          <div className="flex items-center gap-[16px]">
            {socials.map((social, index) => (
              <a href={social.link} key={index}>
              {social.image}
              </a>
            ))}
          </div>
        </div>
        <div className="mt-[60px] lg:mt-0 flex items-center gap-6 lg:gap-0 flex-col lg:flex-row lg:items-start w-full">
          <div className="flex flex-col items-center lg:items-start gap-[16px] w-full">
            <h3 className="font-[700] text-[24px] leading-[31px] md:text-[32px] md:leading-[42px] text-white">
              Need Help?
            </h3>
            <p className="text-[#FAFAFA] font-[400] text-[12px] leading-[16px] md:text-[14px] md:leading-[18px]">
            We Offer 24/7 Hours Service
            </p>
          </div>
          <div className="flex justify-center items-center w-full">
            <Link
              onClick={scrollToTop}
              to="/contactus"
              className="w-[109px] h-[52px] md:w-[158px] md:h-[60px] flex justify-center items-center bg-[#F9FBFE] font-[700] text-[14px] leading-[20px] md:text-[20px] md:leading-[24px] text-center text-primary border-[2px] border-[#161414] rounded-[8px] py-4  px-3 md:py-[18px] md:px-[16px]"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <div className="border border-[#E6E6E6] w-full mt-[60px] mb-[24px] md:mt-[32px] md:mb-[64px]"></div>
      <div className="flex flex-col gap-6 lg:gap-0 lg:flex-row lg:justify-between items-center w-full">
        <div className="flex items-center gap-2 lg:gap-[16px]">
          <p className="font-poppins text-[10px] md:text-[12px] leading-[16px] text-white ">
            English
          </p>
          <LuDot className="w-[4px] h-[4px] text-white"/>
          <Link
            onClick={scrollToTop}
            to="/terms"
            className="font-poppins text-[10px] md:text-[12px] leading-[16px] text-white "
          >
            Terms and Conditions
          </Link>

          <LuDot className="w-[4px] h-[4px] text-white"/>
          <Link
            onClick={scrollToTop}
            to="/privacy"
            className="font-poppins text-[10px] md:text-[12px] leading-[16px] text-white "
          >
            Privacy Policy
          </Link>
        </div>
        <div className="flex justify-center items-center">
          <p className="font-poppins font-[400] text-[10px] md:text-[12px] leading-[16px] text-[#E5E5E5]">
            © 2024 Hamzaa. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
