import React from 'react'
import Header from '../components/Header'
import FeaturesScroll from '../components/FeaturesScroll'
import Features from '../components/Features'
import Companies from '../components/Companies'
import Steps from '../components/Steps'
import Reviews from '../components/Reviews'
import Faq from '../components/Faq'



const Home = ({chatButtonRef, isInFooter}) => {
  return (
    <div className='max-w-full overflow-hidden'>
        <Header isInFooter={isInFooter} chatButtonRef={chatButtonRef} />
        <FeaturesScroll/>
        <Features/>
        <Companies/>
        <Steps/>
        <Reviews/>
        <Faq/>
    </div>
  )
}

export default Home