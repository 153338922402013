import React from 'react'
import HourGlass from '../assets/images/hourglass.png'

const Careers = () => {
  return (
    <div className='mt-[160px] lg:mt-[176px] mb-[80px] font-dmsans max-w-full overflow-hidden px-6 flex flex-col gap-[60px] justify-center items-center'>
        <h1 className='font-spaceGrotesk font-[500] text-[24px] md:text-[32px] leading-[40px] text-black'>Careers</h1>
        <div className='flex flex-col gap-2 items-center'><img src={HourGlass} alt='Hour glass' className='w-[100px] h-[100px] md:w-[200px] md:h-[200px]'/>
        <p className='font-[500] text-[24px] md:text-[32px] leading-[36px] text-[#161414]'>Coming Soon !</p>
        </div>
    </div>
  )
}

export default Careers