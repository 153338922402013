import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ReviewBox = ({image, name, comment}) => {
  const LazyReviewImage = ({ src, alt }) => {
    return <LazyLoadImage alt={alt} src={src} width={40} height={40} effect="blur" />;
  };
  return (
    <div
      data-aos="zoom-in"
      className="flex flex-col gap-[20px]  break-inside-avoid w-full border border-[#E5E7EB99] rounded-[24px] p-[28px] mb-[8px]"
    >
      <div className="flex items-center gap-[12px]">
      <LazyReviewImage src={image} alt={`${name}'s comment`}/>
        <h4 className="font-[400] text-[14px] leading-[21px] text-[#1F2937]">
            {name}
          </h4>
      </div>
      <p className="font-[400] text-[14px] leading-[23px] text-[#4B5563]">
        "{comment}"
      </p>
    </div>
  );
};

export default ReviewBox;
