const faqs = [
    {
      id: 1,
      question: "What is Hamzaa?",
      answer:
        "Hamzaa is an Auto Repair software designed to help automotive repair shops manage customer relationships, streamline operations, track service history, manage inventory, and improve customer retention through automation. Hamzaa offers software solutions to service shops, enabling them to efficiently manage their operations and provide services to their customers. As part of this process, we collect personal information about the shops’ customers, including details such as name, phone number, email, car mileage, and service-related information. We process this information on behalf of and under the instructions of the service shops, who are responsible for managing their customers' personal data. Our services support the shops by facilitating customer communication, improving customer retention, and generates accounting for completed work etc.",
    },
    {
      id: 2,
      question:
        "How secure is the data in Hamzaa?",
      answer:
        "We follow industry-standard security protocols like encryption and data backups, ensuring every customer and operational data remain protected.",
    },
    {
      id: 3,
      question: "What analytics features are available?",
      answer:
        "Hamzaa provides dashboards with real time insights into sales trends, service performance, customer behavior, and financial reports to help you make data-driven decisions.",
    },
    {
      id: 4,
      question: "Can Hamzaa help with marketing campaigns?",
      answer:
        "Yes, it enables segmented campaigns based on your customer history and preferences, helping you promote services and run personalized marketing efforts efficiently.",
    },
    {
      id: 5,
      question: "Can Hamzaa help with part ordering?",
      answer:
        "Yes, Hamzaa is integrated with all part suppliers closest to you to effortlessly supply you with your orders immediately, ensuring smooth operations and happy customers.",
    },
    {
      id: 6,
      question: "How does Hamzaa support customer retention?",
      answer:
        "Hamzaa offers automated text/email reminders, follow-up messages, and recall notifications to maintain continuous engagement with customers. We enhance retention by automating follow-ups, sending service reminders, offering loyalty rewards, and tracking customer interactions to provide personalized service.",
    },
    {
      id: 7,
      question: "What is the cost of Hamzaa?",
      answer:
        "Pricing varies by your shop’s choice and depends on features, and the type of plan (monthly or annual). we offer tiered plans with free trials or demos, referral bonuses and monthly free offers",
    },
  ];

 export default faqs