import React from "react";
import { Link, useLocation } from "react-router-dom";
import useScrollTop from "../custom-hooks/useScrollToTop";

const NavbarModal = ({ setNavbarModalOpen, handleDemoModal }) => {
  const location = useLocation();
  const { scrollToTop } = useScrollTop();
  const navLinks = [
    { id: 1, name: "About Us", path: "/aboutus" },
    { id: 2, name: "What We Offer", path: "/features" },
    { id: 3, name: "Pricing", path: "/pricing" },
    { id: 4, name: "Contact Us", path: "/contactus" },
  ];
  const isActive = (path) => location.pathname === path;
  const handleNavClick = () => {
    setNavbarModalOpen(false);
    scrollToTop();
  };
  const handleDemoButton = () => {
    setNavbarModalOpen(false);
    handleDemoModal();
  };
  return (
    <div className="lg:hidden fixed top-[96px] w-full h-[100vh] bg-white z-40 flex flex-col items-start gap-2 px-6 font-dmsans animate-scale-up-tr">
      {navLinks.map((link, index) => (
        <Link to={link.path} onClick={handleNavClick}>
          <button
            key={index}
            className={`font-[400] text-[14px] leading-[20px] py-6 ${
              isActive(link.path)
                ? "underline underline-offset-4 decoration-[2px] decoration-[#0E4E72] text-[#0E4E72]"
                : "text-[#161414]"
            }`}
          >
            {link.name}
          </button>
        </Link>
      ))}
      <a
        href="https://dashboard.hamzaa.ca/signin"
        rel="noreferrer"
        className="font-[400] text-[14px] leading-[20px] text-[#161414] py-6"
      >
        Login
      </a>
      <a
        href="https://dashboard.hamzaa.ca/signup"
        rel="noreferrer"
        className="font-[400] text-[14px] leading-[20px] text-[#161414] py-6"
      >
        Sign up
      </a>
      <button
        onClick={handleDemoButton}
        className="w-[147px] h-[56px] rounded-[8px] border-[#0A0A0A] border-[2px] py-[16px] px-3 bg-[#2E844A] font-[700] text-[14px] leading-[20px] text-center flex justify-center items-center text-white my-4 "
      >
        Request a Demo
      </button>
    </div>
  );
};

export default NavbarModal;
