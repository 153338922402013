import React from 'react'

const StepBox = ({step, heading, description}) => {
  return (
    <div data-aos="zoom-in" className="group w-full md:w-[390px] md:h-[350px] h-[230px] rounded-[20px] border shadow-md flex flex-col items-center gap-[24px] px-[20px] md:px-[45px] md:py-[18px] py-[10px] font-dmsans hover:bg-steps hover:border-steps bg-white border-[#D9D9D9]">
<div className='w-[104px] h-[38px] md:w-[120px] md:h-[48px] rounded-[30px] py-[10px] px-6 md:px-[32px] flex justify-center items-center text-center mb-[8px] mt-[30px] font-[400] text-[14px] leading-[18px] md:text-[18px] md:leading-[28px] tracking-[0.3px] group-hover:bg-primary group-hover:text-[#FAFAFA] bg-[#F6F9FF] text-[#161414]'>{step}</div>
<h3 className='text-center font-spaceGrotesk font-[700] text-[16px] leading-[20px] md:text-[24px] md:leading-[30px] tracking-[0.3px] text-[#161414] '>{heading}</h3>
<p className='text-center font-[400] text-[12px] leading-[18px] md:text-[20px] md:leading-[30px] tracking-[0.26px] text-[#48494D] '>{description}</p>
    </div>
  )
}

export default StepBox