import CustomerOne from '../assets/images/customer-one.png'
import CustomerTwo from '../assets/images/customer-two.png'
import CustomerThree from '../assets/images/customer-three.png'
import CustomerFour from '../assets/images/customer-four.png'
import CustomerFive from '../assets/images/customer-five.png'
import CustomerSix from '../assets/images/customer-six.png'
import CustomerSeven from '../assets/images/customer-seven.png'
import CustomerEight from '../assets/images/customer-eight.png'
import CustomerNine from '../assets/images/customer-nine.png'


const reviews = [
    {
      id: 1,
      name: "Musa Raul",
      image: CustomerOne,
      comment: "The analytics feature offers real-time insights into customer behavior. This has helped us customize offers, increasing long-term engagement.",
    },
    {
      id: 2,
      name: "Devansh Reddy",
      image: CustomerFour,
      comment:
        "Managing parts has become effortless in Hamzaa. Real-time inventory tracking allows us to order parts just in time, ensuring we meet customer needs without delays.",
    },
    {
      id: 3,
      name: "Benjamin Scott",
      image: CustomerSeven,
      comment:
        "It’s not just for customer retention! Hamzaa has made it easier to convert inquiries into long-term relationships, growing our business steadily. We rely heavily on Hamzaa’s reporting tools to make strategic decisions, helping us identify opportunities to enhance both sales and customer relationships.",
    },

    {
      id: 4,
      name: "Arjun Patel",
      image: CustomerTwo,
      comment:
        "Our shop noticed a sharp increase in customer retention once we started using Hamzaa’s automated follow-up reminders. Customers appreciate the proactive service, which has fostered loyalty over time.",
    },
    {
      id: 5,
      name: "Elijah Hayes",
      image: CustomerFive,
      comment: "The CRM has consolidated all our customer information into one place, simplifying how we manage appointments, order parts, and communicate with clients.",
    },

    {
      id: 6,
      name: "Lily Thompson",
      image: CustomerEight,
      comment:
        "Since adopting Hamzaa CRM, we’ve seen higher satisfaction scores, with customers appreciating proactive service and timely follow-ups.",
    },
    {
      id: 7,
      name: "Saanvi Kapoor",
      image: CustomerThree,
      comment: "Using segmented customer data, we’ve launched targeted campaigns that drive sales while also strengthening customer loyalty.",
    },
    {
      id: 8,
      name: "Samuel Parker",
      image: CustomerSix,
      comment:
        "With integrated maintenance reminders in Hamzaa, we stay connected with customers between visits, resulting in smoother service scheduling and a higher likelihood of repeat business.",
    },

    {
      id: 9,
      name: "Caleb Foster",
      image: CustomerNine,
      comment:
        "Having mobile-friendly features allows our technicians and front-desk staff to stay updated even when they’re on the go, improving efficiency and service quality.",
    },
    {
      id: 10,
      name: "Musa Rose",
      image: CustomerOne,
      comment: "The analytics feature offers real-time insights into customer behavior. This has helped us customize offers, increasing long-term engagement.",
    },
    {
      id: 11,
      name: "Tobiloba Doe",
      image: CustomerFour,
      comment:
        "Automated notifications for recalls and service needs have made customers trust our shop more, reinforcing loyalty and long-term engagement.",
    },
   
  ];
  export default reviews