import React from 'react'
import StepBox from './StepBox';

const Steps = () => {
  const steps = [
    {
      id: 'Step 1',
      heading: 'Sign Up/Login',
      description: 'Create your account with your email or Login into your existing account.',
    },
    {
      id: 'Step 2',
      heading: 'Choose a Pricing Plan',
      description: 'Compare the features and benefits of each plan to choose the one that empowers you to streamline your workflow.',
    },
    {
      id: 'Step 3',
      heading: 'Go To Dashboard',
      description: 'Access your one-stop shop for managing your mechanic business efficiently.',
    },
  ];
  return (
    <div className='flex flex-col items-center mt-[60px] mb-[60px] md:my-[80px]'>
<div className='flex flex-col items-center gap-[6px]'>
  <h2 className='text-center font-spaceGrotesk font-[700] text-[24px] leading-[40px] md:text-[48px] md:leading-[52px] tracking-[0.26px] text-[#161414]'>How it Works</h2>
  <p className='text-center font-[400] text-[14px] leading-[20px] md:text-[20px] md:leading-[28px] tracking-[0.26px] text-[#48494D]'>Easy steps to get you started</p>
</div>
<div className='flex flex-col md:flex-row flex-wrap justify-center gap-6 px-6 md:gap-[55px] mt-[40px] md:mt-[108px]'>
  {steps.map((step, index ) => (
<div  key={index}>
  <StepBox step={step.id} heading={step.heading} description={step.description}
  />
</div>
)
  )}
</div>
    </div>
  )
}

export default Steps