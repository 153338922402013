import React, { useState } from "react";
import reviews from "../utils/reviews";
import ReviewBox from "./ReviewBox";

const Reviews = () => {
  const [mobileReviews, setMobileReviews] = useState(3);
  const [visibleReviews, setVisibleReviews] = useState(9);
  const showMoreReviews = () => {
    setVisibleReviews(reviews.length); // Show all reviews
  };
  const showMoreMobileReviews = () => {
    setMobileReviews(prev => Math.min(prev + 3, reviews.length));
  }
  
  return (
    <div className="flex flex-col items-center gap-[40px] md:gap-[60px] mb-[40px] md:mb-[80px] font-dmsans px-6">
      <div className="flex flex-col items-center gap-2 md:gap-[6px]">
        <h1 className="text-center font-spaceGrotesk font-[700] text-[24px] leading-[40px] md:text-[48px] md:leading-[52px] tracking-[0.26px] text-[#161414]">
          What Our Customers Are Saying
        </h1>
        <p className="text-center font-[400] text-[14px] leading-[20px] md:text-[20px] md:leading-[28px] tracking-[0.26px] text-[#48494D]">
          Testimonials from business owners
        </p>
      </div>
      {/* Mobile view reviews */}
      <div className="flex flex-col items-center gap-[22px] md:hidden">
      {reviews.slice(0, mobileReviews).map((review, index) => (
                <ReviewBox
                  key={index}
                  image={review.image}
                  name={review.name}
                  comment={review.comment}
                />
              ))}
              {mobileReviews < reviews.length && (
              <div className="flex justify-center items-center pb-4">
                <button
                  onClick={showMoreMobileReviews}
                  className="flex justify-center items-center w-[144px] h-[43px] rounded-[9999px] py-[11px] px-[16px] border border-[#E5E7EB99] text-[#1F2937] font-[400] text-[14px] leading-[21px] text-center"
                >
                  Show more
                </button>
              </div>)}
      </div>
      {/* Web view Reviews */}
      <div className="hidden md:flex px-[72px] w-full ustify-center items-center">
        <div className="bg-white w-full h-[955px] relative rounded-[50px] shadow-custom-double border flex justify-center items-center ">
          <div className="overflow-y-auto no-scrollbar max-h-[730px] w-[70%]">
            <div className="columns-1 md:columns-2 xl:columns-3 gap-[12px]">
              {reviews.slice(0, visibleReviews).map((review, index) => (
                <ReviewBox
                  key={index}
                  image={review.image}
                  name={review.name}
                  comment={review.comment}
                />
              ))}
            </div>
          </div>
          {visibleReviews < reviews.length && (
            <>
              <div className="absolute bottom-[50px] w-full h-[280px] bg-gradient-to-t from-white via-white/90 to-transparent"></div>
              <div className="absolute bottom-[80px] left-0 right-0 flex justify-center items-center pb-4">
                <button
                  onClick={showMoreReviews}
                  className="flex justify-center items-center w-[144px] h-[43px] rounded-[9999px] py-[11px] px-[16px] border border-[#E5E7EB99] text-[#1F2937] font-[400] text-[14px] leading-[21px] text-center"
                >
                  Show more
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
