export const privacy = [
{
    id: 1,
    heading: 'Information We Collect',
    explanation: 'We collect personal and business-related information to provide and improve our services, including:',
    points: [
        'Business Information: Shop name, address, phone number, and email address.',
        'Customer Information: End-customer names, phone numbers, email addresses, vehicle details (e.g., mileage, VIN), and service history.',
        'Usage Data: How users interact with the platform to optimize features.',
        'Payment Information: For billing purposes, including payment history and invoices.'
    ]
},
{
    id: 2,
    heading: 'How We Use the Information',
    explanation: 'We use the collected data to:',
    points: [
        'Facilitate smooth business operations, including scheduling, invoicing, and inventory management.',
        'Improve customer retention through automated follow-ups and communication tools.',
        'Provide real-time insights and analytics for better decision-making.',
        'Ensure secure transactions and payment processing.'
    ]
},
{
    id: 3,
    heading: 'How We Share Information',
    explanation: 'We respect your data and will not sell or rent it to third parties. We may share data with:',
    points: [
'Service Providers: Trusted vendors for payment processing, communication tools, or hosting services.',
'Legal Authorities: When required by law to comply with legal obligations.',
'With Your Consent: For any other reason explicitly agreed upon by the user.'
    ]
},
{
    id: 4,
    heading: 'Data Security',
    explanation: 'We take appropriate measures to safeguard your data, including:',
    points: [
'Encryption of sensitive data during transmission and storage.',
'Secure access controls to prevent unauthorized access.',
'Regular security audits and vulnerability assessments.'
    ]
},
{
    id: 5,
    heading: 'Your Rights and Choices',
    explanation: 'Users and customers have the following rights regarding their personal data:',
    points: [
'Access and Update: Request access to personal information stored on the platform and correct inaccuracies.',
'Data Deletion: Request deletion of personal information where applicable.',
'Opt-Out: Choose not to receive marketing or automated notifications.',
    ],
    extra: 'To exercise these rights, please contact us at support@hamzaa.ca.'
},
{
    id: 6,
    heading: 'Third-Party Links and Integrations',
    explanation: 'Our platform may integrate with third-party services (e.g., Nextpart, QuickBooks etc). We are not responsible for the privacy practices of these external services. Please review their privacy policies separately.',
    
},
{
id: 7,
heading: 'Retention of Data',
explanation: 'We retain personal information as long as necessary to provide services or comply with legal obligations. When no longer needed, data is securely deleted or anonymized.'
},
{
id: 8,
heading: "Children’s Privacy",
explanation: 'Our platform is not intended for children under 17, and we do not knowingly collect personal information from minors.'
},
{
id: 9,
heading: 'Changes to This Privacy Policy',
explanation: 'We may update this policy from time to time. Any changes will be communicated via the platform or email notifications. Please review this policy periodically for updates.'
},
{
id: 10,
heading: 'Contact Us',
explanation: 'If you have any questions or concerns regarding this Privacy Policy, please contact us:',
extra: 'Support@hamzaa.ca'
},
];