import React from 'react'
import { privacy } from '../utils/privacy'

const PrivacyPolicy = () => {
  return (
    <div className='mt-[160px] md:mt-[176px] font-dmsans mb-[60px] md:mb-[133px]'>
    <div className='flex flex-col items-center justify-center gap-1 mb-[32px]'>
        <h3 className='font-[400] text-[15px] md:text-[20px] leading-[28px] text-center text-lightGray uppercase'>Understanding our</h3>
        <h1 className='font-spaceGrotesk font-[700] text-[30px] md:text-[60px] leading-[45px] md:leading-[70px] text-center text-[#161414]'>Privacy Policy</h1>
    </div>
    <div className='mx-6 md:mx-[80px]'>
        <h4 className='mb-[30px] font-[400] text-[20px] leading-[28px] text-lightGray'>Effective Date: October 12, 2024</h4>
        <h5 className='font-[400] text-[20px] leading-[28px] text-darkGray'>At Hamzaa, we take your privacy seriously. This Privacy Policy outlines how we collect, use, store, and protect personal information obtained through our platform. By using our services, you agree to the terms described below.
</h5>
<div className='flex flex-col gap-6'>
{
    privacy.map((priv, index) => (
        <div key={index}>
            <h3 className='font-[700] text-[20px] leading-[28px] text-darkGray'>{priv.id}. {priv.heading}</h3>
            <p className='font-[400] text-[20px] leading-[28px] text-darkGray'>{priv.explanation}</p>
            {priv.points && priv.points.length > 0 && (
                <ul className='list-disc ml-5'>
                  {priv.points.map((point, pointIndex) => (
                    <li key={pointIndex} className='font-[400] text-[20px] leading-[28px] text-darkGray'>
                      {point}
                    </li>
                  ))}
                </ul>
              )}
              {priv.extra && (
                <p className='font-[400] text-[20px] leading-[28px] text-darkGray'>{priv.extra}</p>
              )}
              {/* <p className='font-[400] text-[20px] leading-[28px] text-darkGray'> [Insert Phone Number]</p> */}
        </div>
    ))
}
</div>
    </div>
    </div>
  )
}

export default PrivacyPolicy