import React from "react";

const getTagColor = (tag) => {
    switch (tag) {
      case 'RECORD KEEPING':
        return 'bg-[#BDC4FF] text-[#191919]'; 
      case 'MAKE ORDERING EASY':
        return 'bg-[#FFD075] text-[#191919]'; 
      case 'BUILT TO TRACK':
        return 'bg-[#BFE4CB] text-[#191919]'; 
      default:
        return 'bg-[#BDC4FF] text-[#191919]'; 
    }
  };

const FeatureBox = ({ title, description, image, tag, isReversed }) => {
  return (
    <div
    data-aos="fade-down"
      className={`flex flex-col-reverse gap-6 lg:flex-row md:gap-[246px] items-center font-dmsans ${
        isReversed ? "lg:flex-row-reverse" : ""
      }`}
    >
      <div  className="flex  flex-col items-start gap-2 md:gap-[32px] h-auto w-full md:w-[414px]">
      <div className={`w-auto px-5 py-2 rounded-[24px] border border-[#161414] font-[500] text-[12px] leading-[18px] md:text-[14px] md:leading-[20px] tracking-[0.07px] ${getTagColor(tag)}`}>{tag}</div>
        <h3 className="font-spaceGrotesk font-[700] text-[18px] leading-[24px] md:text-[36px] md:leading-[48px] text-[#161414]">{title}</h3>
        <p className="font-[400] text-[12px] leading-[18px] md:text-[18px] md:leading-[28px] tracking-[0.09px] text-[#48494D]">{description}</p>
      </div>
      <div className="" >{image}</div>
    </div>
  );
};

export default FeatureBox;
