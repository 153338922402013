import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
// import { FaSpinner } from 'react-icons/fa';

// const LazyApp = React.lazy(() => import('./App'));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
  <App/>
  {/* <Suspense
						fallback={
							<FaSpinner className='animate-spin mx-auto text-[#00325E] text-4xl my-10' />
						}>
    <LazyApp />
    </Suspense> */}
    </BrowserRouter>
  </React.StrictMode>
);


