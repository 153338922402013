import React from "react";
import Vision from "../assets/images/vision.jpeg";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useBgLoaded from "../custom-hooks/useBgLoaded";

const AboutUs = () => {
  const { ref, inView, bgLoaded, setBgLoaded } = useBgLoaded();

  if (inView && !bgLoaded) {
    setBgLoaded(true);
  }
 
  const LazyMobileMissionImage = ({ src, alt }) => {
    return (
      <LazyLoadImage
        alt={alt}
        src={src}
        style={{ width: "100%", height: "201px" }}
        effect="blur"
        className="w-full block lg:hidden mt-[20px] mb-[40px]  "
      />
    );
  };

  return (
    <div className="mt-[160px] lg:mt-[176px] mb-[60px] lg:mb-[128px] font-dmsans flex flex-col items-center justify-center max-w-full overflow-hidden lg:px-[80px] gap-[40px] lg:gap-[32px] px-6 ">
      <div className=" flex flex-col items-center lg:items-start lg:flex-row justify-center lg:gap-[69px]  w-full">
        <div
          data-aos="fade-down"
          className="w-full flex flex-col items-center lg:itens-start gap-2 "
        >
          <h1 className="font-spaceGrotesk font-[700] md:font-[500] text-[24px] md:text-[32px] leading-[40px] text-black tracking-[0.3px]">
            About Us
          </h1>
          <p className="font-[400] text-[14px] md:text-[16px] md:leading-[28px] leading-[20px] tracking-[0.09px] text-[#6B6666]">
            At Hamzaa, we are dedicated to transforming how auto repair shops
            manage their operations and engage with customers. Our software
            provides an all-in-one solution designed to streamline daily
            workflows, enhance customer retention, and promote long-term
            loyalty. From effortless inventory management and real-time order
            tracking to insightful analytics, our platform equips automotive
            businesses with the tools they need to operate efficiently and grow
            sustainably.
          </p>
        </div>
        <div className="hidden w-full lg:flex justify-between">
        <div
            data-aos="zoom-in"
            ref={ref}
            className={`${
              bgLoaded ? "bg-aboutus-one" : ""
            }   h-[401px] w-[19vw] bg-no-repeat rounded-[10px] bg-contain hidden md:block`}
          ></div>
           <div
            data-aos="zoom-in"
            ref={ref}
            className={`${
              bgLoaded ? "bg-aboutus-two" : ""
            }   h-[401px] w-[19vw] bg-no-repeat rounded-[10px] bg-contain hidden md:block`}
          ></div>
        </div> 
      </div>
      <div className="flex flex-col lg:flex-row w-full justify-center ">
        <LazyMobileMissionImage
          src={Vision}
          alt="A team joining hands together"
          data-aos="zoom-in"
        />

        <div className="flex flex-col w-full lg:mr-8  lg:gap-[46px]">
          <div
            data-aos="zoom-in"
            ref={ref}
            className={`${
              bgLoaded ? "bg-mission-image" : ""
            }   h-[201px] bg-no-repeat rounded-[10px] bg-cover hidden md:block`}
          ></div>
          <div
            data-aos="fade-down"
            className="flex flex-col items-center lg:items-start gap-4"
          >
            <h2 className="font-spaceGrotesk font-[700] md:font-[500] text-[32px] leading-[40px] text-black tracking-[0.3px]">
              Mission
            </h2>
            <p className="font-[400] text-[16px] leading-[28px] tracking-[0.09px] text-[#6B6666] text-center lg:text-left">
              Our mission is to offer dependable and affordable auto repair
              services while building lasting relationships with our customers
              based on trust, transparency, and superior service. Whether
              managing appointments, automating follow-ups, or generating
              detailed invoices, Hamzaa empowers auto shops to stay ahead of
              customer expectations and industry trends.
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full  lg:gap-[46px]">
          <div
            data-aos="zoom-in"
            ref={ref}
            className={`${
              bgLoaded ? "bg-vision-image" : ""
            }  h-[201px] bg-no-repeat rounded-[10px] bg-cover hidden md:block`}
          ></div>

          <div
            data-aos="fade-down"
            className="mt-6 lg:mt-0 flex flex-col items-center lg:items-start gap-4"
          >
            <h2 className="font-spaceGrotesk font-[700] md:font-[500] text-[32px] leading-[40px] text-black tracking-[0.3px]">
              Vision
            </h2>
            <p className="font-[400] text-[16px] leading-[28px] tracking-[0.09px] text-[#6B6666] text-center lg:text-left">
              Our vision is to revolutionize the auto care experience by
              becoming the most customer-centric auto service provider, were
              quality meets convenience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
