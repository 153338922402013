import React from "react";
import star from "../assets/images/slider-star.svg";

const FeaturesScroll = () => {
  const features = [
    "Maintenance",
    "Customer Information",
    "Inventory",
    "Analytics",
    "Report",
    "Invoices",
    "Track Orders",
    "Repairs",
  ];

  return (
    <div className="w-full overflow-hidden bg-[#F6F9FF] h-[40px] md:h-[76px] py-[24px] flex items-center">
    <div className="w-full inline-flex flex-nowrap">
      <ul className="flex items-center [&_li]:mx-2 md:[&_li]:mx-4 [&_img]:max-w-none animate-scroll">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2 md:gap-[16px] px-[10px]">
            <p className="font-spaceGrotesk font-[700] text-[12px] md:text-[18px] leading-[18px] md:leading-[28px] text-[#161414] tracking-[0.01px] whitespace-nowrap">{feature}</p>
            <img src={star} className="md:w-[24px] md:h-[24px] w-[18px] h-[18px]" alt="Star icon" />
          </li>
        ))}
      </ul>
      {/* Duplicate list for smooth scrolling */}
      <ul className="flex items-center [&_li]:mx-2 md:[&_li]:mx-4 [&_img]:max-w-none animate-scroll " aria-hidden="true">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2 md:gap-[16px] px-[10px]">
            <p className="font-spaceGrotesk font-[700] text-[12px] md:text-[18px] leading-[18px] md:leading-[28px] text-[#161414] tracking-[0.01px] whitespace-nowrap">{feature}</p>
            <img src={star} className="md:w-[24px] md:h-[24px] w-[18px] h-[18px]" alt="Star icon" />
          </li>
        ))}
      </ul>
    </div>
  </div>
  );
};

export default FeaturesScroll;
